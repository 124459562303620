import './App.css';
import React, { createContext, useContext, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import PageStore from './store/PageStore';
import UserStore from './store/UserStore';
import AppRouter from './providers/AppRouter';


function App() {
  const { user, page } = useContext(CommonContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // console.log('TRYING TO FETCH USER DATA');
        await user.check();
        
        // console.log('USER DATA FETCHED', user.data);
        // setUser(new UserStore(userData));
      } catch (error) {
        console.error('Failed to fetch user data:', error);
      }
    };

    fetchData();    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  
  return (
    <CommonContext.Provider value={{ user: user, page: page }}>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </CommonContext.Provider>
  );
}


export interface CommonContextAttributes {
  user: UserStore,
  page: PageStore,
}

export const CommonContext = createContext<CommonContextAttributes>({
  user: new UserStore(),
  page: new PageStore(),
});

export default App;