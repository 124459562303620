// ./src/utils/consts.ts
export const MAIN_ROUTE = '/';
export const MAIN_ROUTE_ABOUT = '/about';
export const HELP_ROUTE = '/help';

export const HOME_ROUTE = '/';
export const GAME_ROUTE = '/game';
export const GAME_ROUTE_EXTENDED = '/game/*';

export const AUTH_ROUTE = '/auth/*';
export const LOGIN_ROUTE = '/auth/login';
export const REGISTER_ROUTE = '/auth/link/:linkString';

export const ADMIN_ROUTE = '/admin';
export const USER_ROUTE = '/user';