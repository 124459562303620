import React, { FC, ChangeEvent } from 'react';
import ms from './style/InputElement.module.css';

interface RadioOption {
    label: string;
    value: any;
}

interface RadioGroupProps {
    options: RadioOption[];
    value: boolean;
    onChange: (value: boolean) => void;
}

const RadioGroup: FC<RadioGroupProps> = ({ options, value, onChange }) => {
    return (
        <div className={ms.cb_wrap}>
            {options.map((option) => (
                <label key={option.value} className={ms.cb_label}>
                    <input
                        type="radio"
                        value={option.value}
                        checked={option.value === value}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            const newValue = event.target.value === 'true';
                            onChange(newValue);
                            // console.log(`${option.label} THIS: ${event.target.value}, SETTED: ${value}, MAY BE: ${option.value}`)
                        }}
                    />
                    {option.label}
                </label>
            ))}
        </div>
    );
};

export default RadioGroup;