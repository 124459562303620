import { useEffect, useState } from "react";
import axios from "axios";
import { CardImage } from "./types";

export const useTileData = () => {
    const [cardsList, setCardsList] = useState<CardImage[][]>([]);
    const URI = process.env.REACT_APP_API_URL;
    const URL = `${URI}/games/fp/tiles`;

    useEffect(() => {
        const fetchTiles = async () => {
            const response = await axios.get(URL, {
                withCredentials: true
            });
            const data = response.data;
            data[2] = [...data[0], ...data[1]];
            const list = data.map((imageArray: string[], index: number) => {
                return imageArray.map((src, subIndex) => {
                    return { image: `/static/img/${src}`, matched: false, id: subIndex + 1, flipped: false };
                });
            });
            setCardsList(list);
        };
        fetchTiles();
    }, [URL]);

    return cardsList;
};
