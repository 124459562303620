import React, { useContext, useEffect } from "react";
import { Routes, Route, useNavigate } from 'react-router-dom';
import { observer } from "mobx-react-lite";

import Header from "../components/Header";
import ErrorPage from "../pages/ErrorPage";

import { authRoutes, mainRoutes } from "../utils/routes";
import { CommonContext } from "../App";

/* const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<Root />}>
            <Route path="myComponent" element={<MyComponent />} />
        </Route>
    )
);

const App = () => <RouterProvider router={router} />; */

export default observer(() => {
    const { user, page } = useContext(CommonContext);
    const navigate = useNavigate();

    if (!user) {
        return <>ЗАГРУЗКА</>;
    }

    const checkRoute = (path: string, routes: { path: string; }[]) => {
        return routes.some((route: { path: string; }) => route.path === path);
    }

    const routes = user.isAuthorized ? authRoutes : mainRoutes;

    useEffect(() => {
        if (user.isAuthorized) {
            authRoutes.forEach(({ path, relocate }) => {
                if (window.location.pathname.startsWith('/auth') && relocate) {
                    navigate(relocate);
                }
            });
        }
    }, [user.isAuthorized, navigate]);

    return (
        <>
            {page.getHelmet()}
            <Header />
            <Routes>
                {routes.map(({ path, Component = null }) => (
                    Component !== null ? <Route key={path} path={path} element={<Component />} /> : null
                ))}
                <Route key="unknown" path='/*' element={
                    <ErrorPage errNo={checkRoute(window.location.pathname, [...authRoutes, ...mainRoutes]) ? 403 : 404} />
                } />
            </Routes>
        </>
    );
});
