import AuthPage from "../pages/Authorization/AuthPage";
import RegistrationPage from "../pages/Authorization/RegPage";

import MainPage from "../pages/Unauthorized/MainPage";
import HomePage from "../pages/Authorized/HomePage";

import HelpPage from "../pages/Unauthorized/HelpPage";
import GamePage from "../pages/Authorized/GamePage";
import AdminPage from "../pages/Authorized/admin/AdminPage";

import { MAIN_ROUTE, AUTH_ROUTE, HOME_ROUTE, ADMIN_ROUTE, HELP_ROUTE, REGISTER_ROUTE, GAME_ROUTE_EXTENDED } from "./consts";
import { ComponentType } from "react";

export interface Routes {
    path: string;
    Component?: ComponentType<any>;
    relocate?: string;
}

export const authRoutes: Routes[] = [
    {
        path: HOME_ROUTE,
        Component: HomePage,
    },
    {
        path: GAME_ROUTE_EXTENDED,
        Component: GamePage,
    },
    {
        path: ADMIN_ROUTE,
        Component: AdminPage,
    },
    {
        path: AUTH_ROUTE,
        relocate: HOME_ROUTE
    }
];

export const mainRoutes1: Routes[] = [
    {
        path: MAIN_ROUTE,
        Component: MainPage,
    },
    {
        path: HELP_ROUTE,
        Component: HelpPage,
    },
    {
        path: AUTH_ROUTE,
        Component: AuthPage,
    },
    {
        path: REGISTER_ROUTE,
        Component: RegistrationPage,
    },
];
    
export const mainRoutes: Routes[] = [
    {
        path: MAIN_ROUTE,
        Component: MainPage,
    },
    {
        path: AUTH_ROUTE,
        Component: AuthPage,
    },
    {
        path: REGISTER_ROUTE,
        Component: RegistrationPage,
    },
]