// ./src/providers/ThemeProvider.tsx
import React, { useEffect, useState } from "react";
import jsCookie from "js-cookie";

import { ThemeContext } from "../context/ThemeContext";

interface ThemeProviderProps {
    children: React.ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
    const [darkTheme, setDarkTheme] = useState<boolean>(jsCookie.get('darkTheme') === 'true');

    const toggleTheme = () => {
        setDarkTheme(prevDarkTheme => !prevDarkTheme);
        jsCookie.set('darkTheme', String(!darkTheme));
    };

    useEffect(() => {
        const classList = document.body.classList;
        classList.remove(darkTheme ? 'light-theme' : 'dark-theme');
        classList.add(darkTheme ? 'dark-theme' : 'light-theme');
    }, [darkTheme]);

    return (
        <ThemeContext.Provider value={{ darkTheme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};
