// ./src/pages/Authorized/HomePage.tsx
import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { CommonContext } from "../../App";
import { useNavigate } from "react-router-dom";
import { GAME_ROUTE } from "../../utils/consts";

// import ChatBox from "../../components/ChatBox/ChatBox";

const navigationLinks = {list : [
        {
            title: 'игры',
            link: '/game',
            keepNavigation: true
        }
    ], 
    isPushed: false
};


export default observer(() => {
    const { page, user } = useContext(CommonContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!navigationLinks.isPushed) {

            if (user.isAdmin) {
                navigationLinks.list.push({
                    title: "Управление",
                    link: '/admin',
                    keepNavigation: false
                });
            }
            navigationLinks.isPushed = true;
        }
        page.refresh({dropNavigation: true});
        page.navigation = navigationLinks;
        page.title = 'Главная';


    }, [page, user.isAdmin]);

    useEffect(() => {
        navigate(GAME_ROUTE);
    })
    
    // console.log(navigationLinks);

    return (<div className="page" style={{ display: 'flex' }}>
        <div className="content" style={{ flex: 3 }}>
            <h1>Привет!</h1>
            <h2>Мы очень спешили, поэтому тут пока ничего нет</h2>
        </div>
        {/* <ChatBox /> */}
    </div>);
});