import React, { FC, useContext } from "react";
import ms from './FindPair.module.css'

import { ThemeContext } from "../../../../context/ThemeContext";
import { GameContext } from "./FindPair";

const Tile: FC<{ index: number }> = ({ index }) => {
    const { state, dispatch } = useContext(GameContext);
    const { darkTheme } = useContext(ThemeContext);

    const tile = state.grid![index];
    const uri = process.env.REACT_APP_API_URL!;

    return (
        <div
            className={`${ms.tile} ${ms.dragging} ${index === state.pairIndex ? ms.pair : ''} ${state.isPaused ? ms.tile__paused : ''}`}
            key={index}
            onClick={() => dispatch({ type: 'FLIP_TILE_ACTION', cardIndex: index })}
        >
            <div className={ms.img__container}>
                <img
                    src={uri + (tile.flipped ? tile.image : `/static/img/qLqxW4OShDmpUDeqL0Hv.svg?spc=%23${darkTheme ? 'adadad' : '6e6e6ecc'}`)}
                    alt="tile"
                    style={{
                        opacity: tile.flipped ? 1 : .3,
                        transition: "opacity " + state.speed! / 3 + "ms",
                    }}
                    draggable="false"
                />
            </div>
        </div>
    );

}

export default Tile;