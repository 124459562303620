// ./src/pages/Unauthorized/HelpPage.tsx
import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { navigation } from './MainPage';

import { CommonContext } from '../../App';

export default observer(() => {

    const { page } = useContext(CommonContext);


    useEffect(() => {
        page.refresh();
        page.title = 'help page';
        page.navigation = navigation;
    }, [page]);

    return (
        <div className='page'>
            <h1>HELP PAGE</h1>
        </div>
    );
});