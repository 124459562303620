import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from './providers/ThemeProvider';

const root = ReactDOM.createRoot(document.getElementById('srpcon')!);
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <Helmet
        defaultTitle="srpcon"
      // titleTemplate="%s - Синоним"
      />
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
