// ./src/components/Header.tsx
import ms from './style/Header.module.css';
import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightFromBracket, faUser } from '@fortawesome/free-solid-svg-icons';

import PageStore, { NavigationItem } from '../store/PageStore';

import { CommonContext } from '../App';
import ThemeSwitcher from './ThemeSwitcher';


const Header: React.FC = observer(() => {
    const { page, user } = useContext(CommonContext);

    const navigate = useNavigate();

    if (page.isHeaderHidden)
        return null;

    return (
    <div className={ms.container + " Montserrat600"}>
        {!page.isHeaderMinimized && (
        <div className={ms.synonym}>

                <Link to={page.headerRef || '/'}>{!PageStore.PRIVATE_MODE ? "Синоним" : "тренер"}</Link>

                {/* <Link to="/">{!PageStore.PRIVATE_MODE ? "Синоним" : "label/l."}</Link> */}
            </div>
        )}
        {page.navigation && !page.isHeaderMinimized && (
            <nav className={ms.navigation}>
                <ol>
                    {page.navigation.list.map((item: NavigationItem, index: number) => (
                        <li>
                            <Link to={item.link} key={index}>
                                {item.title}
                            </Link>
                        </li>
                    ))}
                </ol>
            </nav>
        )}
        <div className={ms.buttons}>
            <ThemeSwitcher />
            {user.isAuthorized ? (<button title='profile' onClick={async() => {         
                await user.logout();
                navigate('/');
                // user.isAuthorized = false;
            }}>
                <FontAwesomeIcon icon={faRightFromBracket} />
            </button>) : (<button title='profile' onClick={() => {
                navigate('/auth/login');
            }}>                
                <FontAwesomeIcon icon={faUser} />
            </button>)}
        </div>
    </div>
    );
})

export default Header;