import axios from 'axios';
import React, { createContext, useState, FC, ReactNode } from 'react';

interface GameResult {
    errors: number;
    speed: number;
}

interface GameContextProps {
    gameResult: GameResult | null;
    setGameResult: (result: GameResult) => void;
}


export const sendResults = async (gameId: number, data: any) => {
    await axios.post(`${process.env.REACT_APP_API_URL}/games/game-result`, {
        data,
        gameId,
    }, {
        withCredentials: true
    }).then((response) => {
        console.log(response.data);
    }).catch((error) => {
        console.error(error);
    });
};


export const KeyboardRaceGameContext = createContext<GameContextProps>({
    gameResult: null,
    setGameResult: () => { }
});

export const CrocodileGameContext = createContext<GameContextProps>({
    gameResult: null,
    setGameResult: () => { }
});

export const GameProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [keyboardRaceGameResult, setKeyboardRaceGameResult] = useState<GameResult | null>(null);
    const [crocodileGameResult, setCrocodileGameResult] = useState<GameResult | null>(null);

    return (
    <>
            <KeyboardRaceGameContext.Provider value={{ gameResult: keyboardRaceGameResult, setGameResult: setKeyboardRaceGameResult }}>
                <CrocodileGameContext.Provider value={{ gameResult: crocodileGameResult, setGameResult: setCrocodileGameResult }}>

                    {children}

                </CrocodileGameContext.Provider>
            </KeyboardRaceGameContext.Provider>
        </>
    );
};
