import React, { FC, useContext, useEffect, useState } from 'react';
import axios  from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { CommonContext } from '../../App';
import ErrorPage from '../ErrorPage';

import ms from './AuthPage.module.css'
import InputElement from '../../components/InputElement';
import Notification from '../../components/Notification';

import { LOGIN_ROUTE } from '../../utils/consts';
import InputRadioGroup from '../../components/InputRadioGroup';

interface LinkErrorAttributes {
    code: string;
    message: string;
}

const RegistrationPage: FC = observer(() => {
    const { page, user } = useContext(CommonContext);
    const { linkString } = useParams();
    const [isValidLink, setIsValidLink] = useState<boolean | null>(null);
    const [linkError, setLinkError] = useState<LinkErrorAttributes>({
        code: '',
        message: ''
    });
    const [login, setLogin] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [notification, setNotificationMessage] = useState<string>('');
    const [notificationState, setNotificationState] = useState<boolean>(false);
    const [formError, setFormError] = useState<string>('');
    const [disableForm, setDisableForm] = useState<boolean>(false);
    const [gender, setGender] = useState<boolean>(true);

    const navigate = useNavigate();

    const setNotification = (message: string) => {
        setNotificationMessage(message);
        setNotificationState(true);
        setTimeout(() => {
            setNotificationState(false);
        }, 3000);
    };

    const validateForm = () => {
        if (!login.match(/^[a-zA-Z0-9.]{5,}$/)) {
            return 'Логин (англ.): > 5 символов – буквы, цифры';
        }
        if (!password.match(/^[a-zA-Z0-9\.\-]{8,}$/)) {
            return 'Пароль (англ.): > 8 символов – буквы, цифры';
        }
        if (password !== confirmPassword) {
            return 'Пароли не совпадают';
        }
        if (gender === null) {
            return 'Пожалуйста, выберите пол';
        }
        return '';
    };

    useEffect(() => {
        const checkLink = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/rl/check/${linkString}`);
                if (response.status !== 200) {
                    setIsValidLink(false);
                    setLinkError({
                        code: response.data.code,
                        message: response.data.message,
                    });
                } else {
                    setIsValidLink(true);
                }
            } catch (error: any) {
                setIsValidLink(false);
                if (error.code === 'ERR_NETWORK') {
                    setLinkError({
                        code: error.code,
                        message: 'Ошибка соединения с сервером',
                    });
                    return setNotification('Ошибка соединения с сервером');
                } else if (error.code === "ERR_BAD_REQUEST") {
                    setLinkError({
                        code: "Ссылка недоступна",
                        message: error.response.data.message,
                    });
                }
            }
        };

        checkLink();
        page.refresh({ dropNavigation: true });
        page.title = 'Регистрация';
        page.HeaderDisplayOnlySwitcher();
    }, [linkString, page]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setDisableForm(true);
        const error = validateForm();
        if (error) {
            setFormError(error);
            setDisableForm(false);
            return;
        }

        await user.register(login, password, linkString!, gender);

        if (user.isAuthorized) {
            navigate('/');
        } else {
            setFormError(user.error);
        }
        setDisableForm(false);
    };

    if (isValidLink === null) {
        return <div>Loading...</div>;
    }

    if (isValidLink === false) {
        return (
            <>
                {notification && <Notification message={notification} display={notificationState} />}
                <ErrorPage errCode={linkError.code} errText={linkError.message} />
            </>
        );
    }

    return (
        <div className="page Montserrat600">
            <div className={ms.container}>
                {notification && <Notification message={notification} display={notificationState} />}
                <div className={ms.auth_form}>
                    <div className={ms.grid}>
                        <div className={ms.inputs}>
                            <p className={ms.form_section_name}>Регистрация</p>
                            <form onSubmit={handleSubmit}>
                                <InputElement
                                    label="Логин*"
                                    value={login}
                                    setValue={setLogin}
                                    type="text"
                                    required={true}
                                />
                                <InputElement
                                    label="Пароль*"
                                    value={password}
                                    setValue={setPassword}
                                    type="password"
                                    required={true}
                                />
                                <InputElement
                                    label="Подтверждение пароля*"
                                    value={confirmPassword}
                                    setValue={setConfirmPassword}
                                    type="password"
                                    required={true}
                                />

                                <InputRadioGroup
                                    options={[
                                        { label: 'Мужчина', value: true },
                                        { label: 'Женщина', value: false },
                                    ]}
                                    value={gender}
                                    onChange={setGender}
                                />

                                <button
                                    type="submit"
                                    className={`${ms.btn_next} Montserrat600`}
                                    disabled={disableForm}
                                >
                                    Далее
                                </button>
                            </form>
                            {formError && <div className={ms.error_message}>{formError}</div>}
                        </div>
                        <div className={ms.banner}>
                            <div>
                                <h2>ЗАПРЕЩЕНО УКАЗЫВАТЬ:</h2>
                                <br />
                                <p>– реальные ФИО</p>
                                <p>– данные от рабочего аккаунта</p>
                            </div>
                            {/* <span></span>
                            <div>
                                <Link to="#">Как регистрироваться?</Link>
                                <Link to="#">Что такое {`{PRJ_NAME}`}?</Link>
                                <Link to={LOGIN_ROUTE}>Уже зарегистрированы?</Link>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default RegistrationPage;
