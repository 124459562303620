import { ComponentType } from "react";
import FindPair from "./FindPair/FindPair";
import KeyboardRace from "./KeyboardRace/KeyboardRace";
import Crocodile from "./Crocodile";

interface Route {
    name: string;
    label: string;
    path: string;
    Component?: ComponentType<any>;
}

interface RouteWithPermission extends Route {
    permission: string;
    relocate?: string;
}

export const GamesCommon: Route[] = [
    {
        name: 'findPair',
        label: 'Найди пару',
        path: '/findPair',
        Component: FindPair,
    },
    {
        name: 'keyboardRace',
        label: 'Клавиатурная гонка',
        path: '/keyboardRace',
        Component: KeyboardRace,
    },
];

export const GamesWithPermission: RouteWithPermission[] = [
    {
        name: 'crocodile',
        label: 'Крокодил',
        path: '/crocodile',
        Component: Crocodile,
        permission: "CAN_PLAY_GAME_CROCODILE",
    }
];

export const getGameRoute = (name: string) => {
    return GamesCommon.find((game) => game.name === name);
};

// export const getGameRouteWithPermission = (name: string) => {
//     return GamesWithPermission.find((game) => game.name === name);
// };