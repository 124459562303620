import React, { FC } from "react";

interface ButtonProps {
    title: string;
    className?: string;
    action?: () => void;
}

const Button: FC<ButtonProps> = ({ title, className = null, action = null }) => (
    <button
        className={className || ''}
        onClick={action? action : () => { }}
    >
        {title}
    </button>
);

export default Button;