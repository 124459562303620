// ./src/components/ThemeSwitcher.tsx
import React, { useContext } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleHalfStroke } from '@fortawesome/free-solid-svg-icons';

import { ThemeContext } from '../context/ThemeContext';

const ThemeSwitcher = () => {
    const { toggleTheme } = useContext(ThemeContext);

    return (
        <button title="themeSwitcher" onClick={toggleTheme}>
            <FontAwesomeIcon icon={faCircleHalfStroke} />
        </button>
    );
}

export default ThemeSwitcher;