import React, { FC, useEffect, useContext } from "react";
import ms from "./FindPair.module.css";

import { ThemeContext } from "../../../../context/ThemeContext";
import Button from "../../../../components/Button";
import { getDisplayTime } from "../../../../components/Timer";

import Modal from "./Modal";
import GameField from "./GameField";

import { GameContext, developerMode } from "./FindPair";
import { useTileData } from "./useTileData";
import { shuffle } from "./utils";
import { sendResults } from "../../../../providers/GameProvider";

export const FindPairCore: FC = () => {
    const { darkTheme } = useContext(ThemeContext);
    const cardsList = useTileData();
    const { state, dispatch } = useContext(GameContext);

    useEffect(() => {
        if (cardsList.length > 0 && state.grid.length === 0) {
            startGame();
        }
    });

    useEffect(() => {
        if (state.grid.length > 0 && state.grid.every(tile => tile.matched)) {
            state.levelCompleted = true;
            if (state.level === 3)
                sendResults(1, {
                    time: state.timeElapsed,
                    clicks: state.clicks,
                    hardMode: state.hardMode,
                })
                console.log("Level completed");
            dispatch({ type: 'INCREMENT_LEVEL' });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.grid]);


    useEffect(() => {
        const stop = state.isPaused || state.levelCompleted || !state.gameStarted;
        if (!stop) {
            const interval = setInterval(() => {
                const times = [...state.timeElapsed];
                times[state.level - 1] += 1;
                dispatch({ type: 'SET_TIME', value: times });
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [state.isPaused, state.gameStarted, state.level, state.timeElapsed, state.levelCompleted, dispatch]);

    const startGame = (level = false, time = false, speed = false, clicks = false) => {
        let smth = state;
        smth.grid = [];


        dispatch({ type: 'GAME_RESET_PARAMS', level, speed, time, clicks });

        const nextLevel = state.level;

        if (nextLevel === 4)
            return;

        const imagesList = cardsList[nextLevel - 1];
        const shuffledImages = imagesList.sort(() => Math.random() - 0.5);
        let count = 8;
        if (state.hardMode) {
            if (nextLevel === 2) count = 18;
            if (nextLevel === 3) count = 32;
        }
        const selectedImages = shuffledImages.slice(0, count);
        const levelCards = shuffle([...selectedImages, ...selectedImages].sort(() => Math.random() - 0.5));

        dispatch({ type: 'UPDATE_GRID', value: levelCards });
    };


    const { isPaused, level, speed, timeElapsed: times, levelCompleted: completed, clicks } = state;


    const totalTime = times.reduce((acc, cur) => acc + cur, 0);
    const currentLevel = level - 1;


    return (
        <div className={darkTheme ? ms.theme_dark : ms.theme_light}>

            {developerMode > 0 && (
                <div className={ms.panel_control}>
                    <p>Уровень: {level} | {getDisplayTime(times[state.level - 1])}</p>
                </div>
            )}

            <GameField />
            {isPaused && !completed &&
                <Modal caption={`Игра на паузе`}>
                    <Button action={() => dispatch({ type: 'TOGGLE_PAUSE' })} title="Продолжить" />
                </Modal>}
            {completed &&
                <Modal caption={`Раунд ${currentLevel} пройден!`}>
                    <p>Скорость: {(speed / 1000).toFixed(2)}</p>
                    <p>Понадобилось: {getDisplayTime(times[currentLevel - 1])}, нажатий: {clicks[currentLevel - 1]}</p>
                    {currentLevel === 3 ? <>
                        <br />
                        <p>Общее время {getDisplayTime(totalTime)} сек.</p>
                        <p>Паузы не учитываются</p>
                        <br />
                        {times.map((time, index) => {
                            return <p key={index}>Раунд {index + 1}: {getDisplayTime(time)} | Кликов: {clicks[index]}</p>;
                        })}
                        <div className={ms.modal__buttons}>
                            <Button action={() => {
                                startGame(true, true, true, true);
                                dispatch({ type: 'START_GAME' });
                            }} title="Начать заново?" />
                        </div>
                    </> : <>
                        <br />
                        <Button action={() => {
                            // dispatch({ type: 'INCREMENT_LEVEL' })
                            startGame();
                        }} title="Следующий раунд" className={ms.modal_button} />
                    </>}
                </Modal>}
            <div className={ms.panel_control}>
                {!state.gameStarted ? (
                    <Button action={() => dispatch({ type: 'START_GAME' })} title="Начать игру" className={ms.modal_button} />
                ) : (<>
                    <Button action={() => dispatch({ type: 'TOGGLE_PAUSE' })} title={isPaused ? "На паузе" : "Пауза"} />
                    {state.developerMode > 0 && (
                        <Button action={() => startGame(true, true, true, true)} title="Сбросить сетку" className={ms.modal_button} />
                    )}
                </>)}
            </div>
        </div>
    );
};
