// ./src/pages/Authorized/admin/AdminPage.tsx
import ms from "./AdminPage.module.css";
import React from "react";
import { observer } from "mobx-react-lite";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGamepad, faUser } from "@fortawesome/free-solid-svg-icons";

export default observer(() => {

    return (
    <div className={`${ms.sidebar} montserrat`}>
        <div className={ms.category}>
                <div className={ms.cat_label}>
                    <FontAwesomeIcon icon={faUser} />
                    <span>Пользователи</span>
                </div>
                <div className={ms.cat_content}>
                    <div className={ms.cat_item}>
                        <span>Модераторы</span>
                    </div>
                    <div className={ms.cat_item}>
                        <span>Группы</span>
                    </div>
                    <div className={ms.cat_item}>
                        <span>Игроки</span>
                    </div>
                </div>
            </div>
            <div className={ms.category}>
                <div className={ms.cat_label}>
                    <FontAwesomeIcon icon={faGamepad} />
                    <span>Игры</span>
                </div>
                <div className={ms.cat_content}>
                    <div className={ms.cat_item}>
                        <span>Крокодил</span>
                    </div>
                    <div className={ms.cat_item}>
                        <span>Найди пару</span>
                    </div>
                </div>
            </div>
        </div>
    );
});