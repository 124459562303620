import React, { createContext, useReducer, FC, ReactNode, useEffect, useContext } from "react";
import { GameState, GameAction } from "./types";
import { gameReducer } from "./gameReducer";
import { FindPairCore } from "./FindPairCore";

import { CommonContext } from '../../../../App';
import { GAME_ROUTE } from '../../../../utils/consts';

export const developerMode: number = 0;

const initialState: GameState = {
    grid: [],
    level: 1,
    speed: 600,
    startSpeed: 600,
    speedDecrease: 600 / 4,
    timeElapsed: [0, 0, 0],
    isPaused: false,
    gameStarted: false,
    hardMode: false,
    firstFlip: null,
    pairIndex: null,
    developerMode: developerMode,
    levelCompleted: true,
    changeMode: false,
    clicks: [0, 0, 0],
}

export const GameContext = createContext<{ state: GameState; dispatch: React.Dispatch<GameAction>; }>({
    state: initialState,
    dispatch: () => null
});

export const FindPairProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [state, dispatch] = useReducer(gameReducer, initialState);
    
    return (
        <GameContext.Provider value={{ state, dispatch }}>
            {children}
        </GameContext.Provider>
    );
}

export const FindPair: FC = () => {
    const { page } = useContext(CommonContext);
    
    useEffect(() => {
        page.refresh({ dropNavigation: true });
        page.title = 'Игра: Найди пару';
        page.headerRef = GAME_ROUTE;
    })
    
    return (
        <FindPairProvider>
            <FindPairCore />
        </FindPairProvider>
    );
}

export default FindPair;
