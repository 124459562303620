import { makeAutoObservable } from "mobx";
import axios from "axios";

export default class UserStore {
    private _isAdmin: boolean = false;
    private _isAuthorized: boolean = false;
    private _username: string | null = null;
    public error: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    get isAuthorized() {
        return this._isAuthorized;
    }

    set isAuthorized(bool: boolean) {
        this._isAuthorized = bool;
    }

    get data() {
        return JSON.stringify({
            isAuthorized: this._isAuthorized,
            username: this._username,
            isAdmin: this._isAdmin,
        });
    }

    get username() { return this._username; }
    get isAdmin() { return this._isAdmin; }

    async register(login: string, password: string, linkString: string, gender: boolean) {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/register`, {
                randStr: linkString,
                login,
                password,
                gender
            }, {
                withCredentials: true
            });

            this._isAuthorized = true;
            this._username = response.data.user.visibleName;

        } catch (error: any) {
            if (error.response && error.response.status === 400) {
                if (error.response.data.code === "EXISTS") {
                    this.error = 'Пользователь с таким логином уже существует';
                } else {
                    this.error = 'Произошла ошибка';
                }
            }
        }
    }

    async login(login: string, password: string) {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, {
                login,
                password,
            }, {
                withCredentials: true
            }).then((response) => {
                this._username = response.data.user.visibleName;
                this._isAuthorized = true;
            })
            return true;
        } catch (error: any) {
            this.error = 'Неверный логин или пароль';
            return false;
        }
    }

    async check() {

        const options = {
            withCredentials: true
        };

        await axios.get(`${process.env.REACT_APP_API_URL}/user/fetch`, options)
        .then(response => {
            this._isAuthorized = true;
            this._username = response.data.visibleName;
            // console.log(response.data);
        })
        .catch(error => {
            this._isAuthorized = false;
            console.error('Failed to fetch user data:', error);
        });
    }

    async logout() {
        await axios.post(`${process.env.REACT_APP_API_URL}/auth/logout`, {}, {
            withCredentials: true
        });
        this._isAuthorized = false;
        this._username = null;
    }
}
