import React, { useState, useEffect, useRef, useContext } from 'react';
import { observer } from "mobx-react-lite";
import { KeyboardRaceGameContext, sendResults } from '../../../../providers/GameProvider'
import ms from './KeyboardRace.module.css';
import { TimerPlus } from '../../../../components/Timer';
import { ThemeContext } from '../../../../context/ThemeContext';
import Cookies from 'js-cookie';

import { CommonContext } from '../../../../App';
import { GAME_ROUTE } from '../../../../utils/consts';

const texts = [
    {
        id: 1,
        title: "Диктант",
        text: "Николай Сергеевич и его жена впервые в жизни приехали в Абхазию из Москвы и жили на летней даче художника Андрея Таркилова, который редко бывал здесь. Под крышами крестьянских домов, мимо которых они проходили к морю, лепились ласточкины гнёзда. Странно, но под крышей дачи не было ни одного гнезда, хотя дом был выстроен более десяти лет назад."
    },
    {
        id: 2,
        title: "Негатив",
        text: "Мне очень жаль, что у вас сложилось такое впечатление о нашем банке. Будем надеяться, что в будущем ваше мнение сможет измениться в лучшую сторону. Условия наших акций прозрачны и, если выполняются условия, бонус поступает в срок автоматически. Да, бывают, конечно же случаи, когда что-то может пойти не так, но мы никогда не отказываемся проверить это и исправить."
    },
    {
        id: 3,
        "title": "Утро в лесу",
        "text": "Лес просыпался под первыми лучами солнца. Птицы начинали петь свои утренние песни, приветствуя новый день. Воздух был свежим, наполненным ароматом хвои и трав. На тропинке можно было заметить следы ночных животных, а вдали слышался шум реки. Природа оживала, и лес становился полон жизни и звуков."
    },
    {
        id: 4,
        "title": "Городская суета",
        "text": "В городе все спешат по своим делам. Улицы полны машин, автобусов и велосипедов. Люди торопятся на работу, в магазины, на встречи. Светофоры мигают, сигналят автомобили. Везде слышен шум города: гудки, разговоры, шаги. Но среди всей этой суеты каждый человек живет своей жизнью, со своими мыслями и планами."
    },
    {
        id: 5,
        "title": "Морское путешествие",
        "text": "Путешествие на корабле по морю - это незабываемое приключение. Ветер развевает волосы, соленые брызги освежают лицо. Вдали виднеются острова, каждый из которых хранит свои тайны и загадки. Вечерами можно наблюдать, как солнце медленно опускается за горизонт, окрашивая небо в яркие краски. Ночью звезды кажутся ближе и ярче."
    },
    {
        id: 6,
        "title": "Осенний парк",
        "text": "Осенью парк преображается. Деревья надевают золотые и багряные наряды, листья мягко падают на землю, устилая ее пестрым ковром. В воздухе пахнет влажной землей и опавшими листьями. Тишина нарушается лишь редкими звуками шагов прохожих и шорохом листвы. Парк становится местом для размышлений и спокойных прогулок."
    },
    {
        id: 7,
        "title": "Ночной город",
        "text": "Ночью город обретает особую атмосферу. Огни улиц и витрин создают удивительные световые картины. Тихие переулки и оживленные проспекты выглядят по-разному, но везде ощущается таинственность ночи. Множество окон светятся, за каждым из них своя история. Город не спит, его жизнь продолжается в новом ритме."
    }
]


const KeyboardRace: React.FC = observer(() => {
    const [isStarted, setIsStarted] = useState(false);
    const [inputText, setInputText] = useState('');
    const [startTime, setStartTime] = useState<number | null>(null);
    const [endTime, setEndTime] = useState<number | null>(null);
    const [errors, setErrors] = useState(0);
    const inputRef = useRef<HTMLInputElement>(null);
    const { setGameResult } = useContext(KeyboardRaceGameContext);

    const { darkTheme } = useContext(ThemeContext);

    const [textIndex, setTextIndex] = useState<number>(0);
    const [sampleText, setSampleText] = useState<string>(texts[0].text);

    const { page } = useContext(CommonContext);

    const handleStart = (start: boolean = false) => {
        setInputText('');
        setErrors(0);
        setEndTime(null);
        setIsStarted(false);

        if (start) {
            setIsStarted(start);
            setStartTime(Date.now());
        }
    };

    useEffect(() => {
        page.refresh();
        page.title = "Игра: Клавиатурная гонка";
        page.headerRef = GAME_ROUTE;
    }, []);

    useEffect(() => {
        if (Cookies.get('textIndex')!== undefined) {
            const index = parseInt(Cookies.get('textIndex')!);
            if (texts[index]) {
                setTextIndex(index);
                setSampleText(texts[index].text);
            }
        }
        // if (Cookies.get('textIndex') !== undefined) {
            // setTextIndex(parseInt(Cookies.get('textIndex')));
        // }
    }, [])

    useEffect(() => {
        if (isStarted && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isStarted]);
    
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        if (value.length <=  sampleText.length) {
            let newErrors = 0;
            for (let i = 0; i < value.length; i++) {
                if (value[i] !== sampleText[i]) {
                    newErrors++;
                }
            }

            if (newErrors > 1) {
                e.preventDefault();
                return;
            }

            setInputText(value);
            setErrors(errors + newErrors);

            if (value === sampleText) {
                setEndTime(Date.now());
                setIsStarted(false);
            }
        }
    };

    useEffect(() => {
        if (endTime && startTime) {
            const duration = (endTime - startTime) / 1000; 
            const speed = (sampleText.length / duration) * 60;
            setGameResult({ errors, speed });
            sendResults(2, {
                text: {
                    id: texts[textIndex].id,
                    title: texts[textIndex].title,
                },
                errors,
                speed,
                duration,
            });
        }
    }, [endTime, startTime]);

    const changeText = (index: number) => {
        if (isStarted)
            return;

        setTextIndex(index);
        setSampleText(texts[index].text);
        handleStart();

        Cookies.set('textIndex', index.toString());
    }
    
    const renderText = () => {
        return sampleText.split('').map((char, index) => {
            let className = '';
            let correct = false;
            if (index < inputText.length) {
                correct = inputText[index] === char;
                className = correct ? ms.correct : ms.incorrect;
            }
            return (
                <span key={index} className={className}>
                    {(char === ' ') ? (
                        <>
                        {(inputText[index + 1] && inputText[index] !== char) ? inputText[index] : char}

                        </>
                    ) : (<>
                    {char}
                    </>
                    
                    )}
                    {/* {(!correct && char === ' ' && char === inputText[index]) ? "!" : char} */}
                </span>
            );
        });
    };

    return (
        <div className={`${ms.container} ${darkTheme ? ms.theme_dark : ms.theme_light}`}>
            <div className={ms.text_content}>
                <p>Попробуй написать это быстро:</p>
                {!isStarted ? (<>
                    <p className={ms.text}>{renderText()}</p>
                    <button className={ms.button} onClick={() => handleStart(true)}>Начать</button>
                </>) : (<>
                    <div className={ms.sub_containter}>
                        <p className={ms.text}>{renderText()}</p>
                        <input
                            title='Инпут для ввода текста'
                            ref={inputRef}
                            type="text"
                            value={inputText}
                            onChange={handleChange}
                            className={ms.input}
                            autoComplete="off"
                            autoCorrect='off'
                            autoFocus={true}
                            spellCheck={false}
                            
                        />
                    </div>
                        <TimerPlus start={true} />
                        {/* <p>Ошибки: {errors}</p> */}
                </>)}

                {endTime && (
                    <div className={ms.results}>
                        <p>{(sampleText.length / ((endTime - startTime!) / 1000) * 60).toFixed(2)} символов в минуту</p>
                        <p>ошибок: {errors}</p>
                    </div>
                )}
            </div>
            <div className={ms.selector}>
                {texts.map((text, index) => {
                    return (<div 
                        key={text.text + "#" + index}
                        className={ms.selector_i}
                        onClick={() => changeText(index)}
                    >
                        {text.title}
                    </div>)
                })}
            </div>
        </div>
    );
});

export default KeyboardRace;
