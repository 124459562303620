// ./src/store/PageStore.tsx
import React from 'react';
import { Helmet } from 'react-helmet';
import { makeAutoObservable } from 'mobx';

const PM = true;

interface Navigation {
    list: NavigationItem[];
    isPushed?: boolean | null;
}

interface NavigationItem {
    link: string,
    title: string
}

export type { Navigation, NavigationItem };

export default class PageStore {
    private _title: string | null;
    private _icon: string | null;
    private _description: string | null;
    private _isHeaderHidden: boolean;
    private _isHeaderMinimized: boolean = false;
    private _navigation: Navigation | null;
    private _headerRef: string | null;

    static PRIVATE_MODE: boolean = PM;
    // static isHeaderHidden: any;
    static navigation: any;

    constructor(
        title: string | null = null,
        description: string | null = null,
        icon: string | null = null,
        hideHeader: boolean | null = null,
        navigation: Navigation | null = null,
        headerRef: string | null = null,
    ) {
        this._title = title || null;
        this._icon = icon || null;
        this._description = description || null;
        this._isHeaderHidden = hideHeader || false;
        this._navigation = navigation || null;
        this._headerRef = headerRef || null;

        makeAutoObservable(this);
    }

    public get title(): string | null {
        return this._title;
    }
    
    public set title(value: string | null){
        this._title = value;
    }

    public get icon(): string | null {
        return this._icon;
    }

    public set icon(value: string | null) {
        this._icon = value;
    }

    public get description(): string | null {
        return this._description;
    }

    public set description(value: string | null) {
        this._description = value;
    }

    public get isHeaderHidden(): boolean {
        return this._isHeaderHidden;
    }

    public set isHeaderHidden(value: boolean) {
        this._isHeaderHidden = value;
    }

    public get isHeaderMinimized(): boolean {
        return this._isHeaderMinimized;
    }
    
    public get navigation(): Navigation | null {
        return this._navigation;
    }
    
    public set navigation(value: Navigation | null) {
        this._navigation = value;
    }

    public get headerRef(): string | null {
        return this._headerRef;
    }

    public set headerRef(value: string | null) {
        this._headerRef = value;
    }

    public refresh = (data: any = null): void => {
        this.default();
        if (!data) return;
        if (data.dropNavigation) this._navigation = null;
    };

    public default = (): void => {
        this._description = "Страница без описания";
        this._icon = null;
        this._title = !PageStore.PRIVATE_MODE ? 'Синоним' : "тренажёр";
        this._isHeaderHidden = false;
        this._isHeaderMinimized = false;
    };

    public HeaderDisplayOnlySwitcher = () : void => {
        this._isHeaderHidden = false;
        this._isHeaderMinimized = true;
    }

    public getHelmet = () => (
        <Helmet>
            <title>{this._title}</title>
            <meta name="description" content={this._description || ''} />
        </Helmet>
    );
}
