import ms from './AuthPage.module.css'

import React, { FC, useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { CommonContext } from '../../App';
import InputElement from '../../components/InputElement';

const LoginPage: FC = observer(() => {
    const [login, setLogin] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [formError, setFormError] = useState<string>('');
    const navigate = useNavigate();

    const { user, page } = useContext(CommonContext);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setFormError('');
        await user.login(login, password);
        if (user.isAuthorized) {
            navigate('/');
        } else {
            setFormError(user.error);
        }
    };

    useEffect(() => {
        page.refresh({ dropNavigation: true });
        page.title = 'Авторизация';
	page.headerRef = "#";
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="page Montserrat600">
            <div className={ms.container}>
                <div className={`${ms.auth_form} ${ms.form_minimized}`}>
                    <div className={`${ms.inputs} ${ms.inputs_max}`}>
                        <p className={ms.form_section_name}>
                            Вход
                        </p>
                        <form onSubmit={handleSubmit}>
                            <InputElement
                                label="Логин"
                                value={login}
                                setValue={setLogin}
                                type="text"
                                required={true}
                            />
                            <InputElement
                                label="Пароль"
                                value={password}
                                setValue={setPassword}
                                type="password"
                                required={true}
                            />

                            <button
                                type="submit"
                                className={`${ms.btn_next} Montserrat600`}
                            >
                                Войти
                            </button>
                            <div style={{ textAlign: "center" }}>
                                <Link to='#'>
                                    Как зарегистрироваться?
                                </Link>
                            </div>
                        </form>
                        {formError && <div className={ms.error_message}>{formError}</div>}
                    </div>
                </div>
            </div>
        </div>
    );
});

export default LoginPage;
