import { GameState, GameAction } from "./types";
import { developerMode } from "./FindPair";

export const gameReducer = (state: GameState, action: GameAction): GameState => {
    switch (action.type) {
        case 'GAME_RESET_PARAMS':
            return {
                ...state,
                isPaused: false,
                levelCompleted: false,
                grid: [],
                firstFlip: null,
                pairIndex: null,
                clicks: action.clicks ? [0, 0, 0] : state.clicks,
                level: action.level ? 1 : state.level,
                speed: action.speed ? state.startSpeed : state.speed,
                timeElapsed: action.time ? [0, 0, 0] : state.timeElapsed,
                hardMode: state.changeMode ? !state.hardMode : state.hardMode,
            };
        case 'START_GAME': {
            return {
                ...state,
                gameStarted: !state.gameStarted,
            };
        }
        case 'TOGGLE_PAUSE':
            return {
                ...state,
                isPaused: !state.isPaused
            };
        case 'SET_SPEED':
            return {
                ...state,
                speed: action.value
            };
        case 'INCREMENT_LEVEL':
            const newLevel = state.level + 1;
            // const newLevel = state.level === 3 ? 1 : state.level + 1;
            return {
                ...state,
                level: newLevel,
                speed: newLevel === 1 ? state.startSpeed : state.speed - state.speedDecrease,
            };
        case 'UPDATE_GRID':
            return {
                ...state,
                grid: action.value
            };

        case 'SET_PAIR_INDEX':
            return {
                ...state,
                pairIndex: action.pairIndex
            };

        case 'SET_TIME':
            return {
                ...state,
                timeElapsed: action.value
            };

        case 'FLIP_TILE_ACTION':
            if (!state.gameStarted) 
                state.gameStarted = true;
            const index = action.cardIndex;
            const grid = [...state.grid];
            if (grid[index].matched || grid[index].flipped || grid.filter(tile => tile.flipped && !tile.matched).length === 2 || state.isPaused) return state;

            grid[index] = { ...grid[index], flipped: true };
            state.clicks[state.level - 1]++;;

            const analyze = () => {

                if (state.developerMode > 0) {
                    let pairIndex = -1;
                    for (let i = 0; i < grid.length; i++) {
                        if (grid[i].image === grid[index].image && i !== index) {
                            pairIndex = i;
                        }
                    }

                    if (developerMode === 2) {
                        grid[index!] = {
                            ...grid[index!],
                            flipped: true,
                            matched: true
                        };
                        grid[pairIndex] = {
                            ...grid[pairIndex],
                            flipped: true,
                            matched: true
                        };

                        return {
                            ...state,
                            grid
                        };
                    }

                    if (developerMode === 1) {
                        state.pairIndex = pairIndex;
                    }
                }

                if (state.firstFlip === null) {
                    return {
                        ...state,
                        grid,
                        firstFlip: index
                    };
                } else {
                    if (grid[state.firstFlip].image === grid[index].image) {
                        grid[state.firstFlip] = { ...grid[state.firstFlip], matched: true };
                        grid[index] = { ...grid[index], matched: true };
                        return {
                            ...state,
                            grid,
                            firstFlip: null
                        };
                    } else {
                        setTimeout(() => {
                            grid[state.firstFlip!] = { ...grid[state.firstFlip!], flipped: false };
                            grid[index] = { ...grid[index], flipped: false };
                            return {
                                ...state,
                                grid,
                                firstFlip: null,
                            };
                        }, state.speed);
                        return {
                            ...state,
                            grid,
                            firstFlip: null,
                            pairIndex: null
                        };
                    }
                }
            };

            let result = analyze();

            return {
                ...result,
                // levelCompleted: result.grid.every(tile => tile.matched),
            };

        case 'CHANGE_MODE':
            return {
                ...state,
                changeMode: !state.changeMode
            };

        default:
            console.error("UNHANDLED ACTION", action);
            return state;
    }
};
