import React, { FC, ReactNode } from "react";
import ms from './FindPair.module.css';

interface ModelProps {
    caption: string;
    children: ReactNode;
}

const Modal: FC<ModelProps> = ({ caption, children }) => {
    return (
        <div className={`${ms.modal}`}>
            <div className={ms.modal__caption}>
                {caption}
            </div>
            {children}
        </div>
    );
};

export default Modal;