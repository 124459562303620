import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import ms from './style/Notification.module.css';

const Notification: FC<{ message: string, display: boolean }> = ({ message, display }) => {
    return (
        <div className={`${ms.notification} ${display ? ms.notification_active : ''}`}>
            <div className={ms.notification_content}>
                <div className={ms.notification_icon}>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                </div>
                <div className={ms.notification_text}>
                    <p>{message}</p>
                </div>
            </div>
        </div>
    );
}

export default Notification;