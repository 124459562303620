// ./src/pages/Authorized/GamePage.tsx
import ms from "./style/GamePage.module.css";
import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import ChatBox from "../../components/ChatBox/ChatBox";
import InputWall from "../../components/InputWall";
import { Timer } from "../../components/Timer";

import Crocodile from "./games/Crocodile";

import { CommonContext } from "../../App";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import { GamesCommon } from "./games/GamesRoutes";
import { GAME_ROUTE } from "../../utils/consts";
import { GameProvider } from '../../providers/GameProvider'
import { ThemeContext } from "../../context/ThemeContext";

const FieldDefault = () => {
    return (
        <div className={ms.content}>
            <p>Ваши варианты:</p>
            <div className={ms.pad_group}>
                <InputWall />
            </div>
        </div>
    );
}

export default observer(() => {
    const { page, user } = useContext(CommonContext);
    const { darkTheme } = useContext(ThemeContext);

    const location = useLocation();

    const [isMainMenu, setIsMainMenu] = useState(false);

    useEffect(() => {
        page.refresh({ dropNavigation: true });
        page.title = "Доступные игры";
        page.headerRef = GAME_ROUTE;
    }, [page]);


    
    useEffect(() => {
        // mysite/game is mysite/GAME_ROUTE
        const regex = /^\/?game\/?$/.test(location.pathname);
        const temp_inMain = location.pathname === '/';


        setIsMainMenu(regex || temp_inMain);
    }, [location.pathname])
    

    return (<div className={`${ms.page} montserrat`}>

        {isMainMenu ? (<div className={`${ms.container} ${darkTheme ? ms.theme_dark : ms.theme_light}`}>
            <div className={ms.sub_header}>
                <h1>
                    доступные игры
                </h1>
                <h2>мы пока только разрабатываем их</h2>
            </div>

            <div className={ms.carousel}>
                {GamesCommon.map(({ name, label, path }) => (
                    <Link to={`${GAME_ROUTE}${path}`}>
                        <div key={name} className={ms.carousel_item}>
                            <div className={ms.carousel_desc}>
                                <h2 className={ms.carousel_item_name}>{label}</h2>
                                <p>[перейти]</p>
                            </div>
                        </div>
                    </Link>
                ))}

            </div>

            <div className={ms.sub_footer}>
                <p>имя: {user.username}</p>
            </div>
        </div>) : (<div className={`${ms.container} ${ms.flex_centered}`}>
            <GameProvider>
                <Routes>
                    {GamesCommon.map(({ path, Component = null }) => (
                        Component!== null? <Route key={path} path={path} element={<Component />} /> : null
                    ))}
                </Routes>
            </GameProvider>
        </div>)}


            
            {/* <div className={ms.sub_header}>
                <h1>
                    10 способов
                    <Timer 
                        className={ms.timer}
                        duration={90}
                    />
                </h1>
                <h2>нестандартно применить кирпич</h2>
            </div> */}

            {/* <FieldDefault /> */}
            {/* < Crocodile /> */}


            

            {/* <div className={ms.sub_footer}>
                <p>[настройки]</p>
                <p>[подсказка]</p>
            </div> */}

        {/* <ChatBox context={GameContext} /> */}
        {/* <ChatBox /> */}
    </div>);
})