// ./src/pages/Authorized/games/Crocodile.tsx
import ms from './Crocodile.module.css';
import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { ThemeContext } from '../../../context/ThemeContext';
import { getDisplayTime } from '../../../components/Timer';

import alienImage from '../../../static/images/alien.png';


interface Word {
    word: string;
    picture: string;
}

const words: Word[] = [
    {
        word: 'инопланетянин',
        picture: alienImage,
    },
    {
        word: 'крокодил',
        picture: alienImage,
    },
    {
        word: 'программист',
        picture: alienImage,
    },
    // some random words
    {
        word: 'самолет',
        picture: alienImage,
    },
    {
        word: 'компьютер',
        picture: alienImage,
    },
    {
        word: 'генератор',
        picture: alienImage,
    }
]

export default observer(() => {
    const { darkTheme } = useContext(ThemeContext);

    const [flipped, setFlipped] = useState(false);
    const [wordIndex, setWordIndex] = useState(0);
    const [timer, setTimer] = useState(90);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimer(prevTimer => prevTimer - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const changeWord = () => {
        if (wordIndex === words.length - 1)
            return;
        setFlipped(true);
        setTimeout(() => {
            setFlipped(false);
            setWordIndex(prevIndex => prevIndex + 1);
            setTimer(90);
        }, 500);
    };

    return (
        <div className={`${ms.wrapper} ${darkTheme ? ms.theme_dark : ms.theme_light}`}>
            <div className={`${ms.card} ${flipped ? ms.flipped : ''}`}>
                <div className={`${ms.ls} ${ms.jc}`}>
                    <p>слово #{wordIndex + 1}</p>
                    <p>угадано: 0</p>
                </div>
                <div className={ms.la}>
                    <img className={ms.pic} src={words[wordIndex].picture} alt="" />
                    <p className={`${ms.word} ${ms.word_timer}`}>{words[wordIndex].word}</p>
                    <p className={ms.timer}>{getDisplayTime(timer)}</p>
                </div>
                <div className={ms.ls}>
                    <p className={ms.lsb} onClick={changeWord}>пропустить</p>
                </div>
            </div>
        </div>
    );
});
