// ./src/pages/ErrorPage.tsx
import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { CommonContext } from '../App';

interface ErrorPageProps {
    errNo?: number;
    errCode?: string;
    errText?: string;
}

export default observer(({ errNo, errCode, errText }: ErrorPageProps) => {
    const { page } = useContext(CommonContext);

    useEffect(() => {
        page.refresh({ dropNavigation: true });
        page.title = "Ошибка " + errNo;
    }, [page, errNo]);


    return (
        <div className="container" style={{ marginTop: '20vh', textAlign: 'center' }}>
            <h1 style={{ color: 'gray', fontSize: '4em' }}>
                <FontAwesomeIcon icon={faExclamationTriangle} /> {errNo || errCode}
            </h1>
            <h2>{errNo && (errNo === 404 ? 'Страница не найдена' : 'Нет доступа')}</h2>
            <p>{errText}</p>
            <Link to="/" className="btn">На главную</Link>
        </div>
    );
});
