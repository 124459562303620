// ./src/pages/Unauthorized/MainPage.tsx
import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { CommonContext } from '../../App';
import { Link } from 'react-router-dom';
import LoginPage from '../../pages/Authorization/AuthPage';

export const navigation = {list: [
    {
        title: 'Главная',
        link: '/',
        keepNavigation: true
    },
    {
        title: 'О проекте',
        link: '/about',
        keepNavigation: true
    },
    {
        title: 'Помощь',
        link: '/help',
        keepNavigation: true
    }
]};

export default observer(() => {
    const { page } = useContext(CommonContext);

    useEffect(() => {
        page.refresh();
        page.title = 'Главная';
        // page.navigation = navigation;
    }, [page]);

    return (<div className='page centered_flex montserrat'>
        <LoginPage />
    </div>);
});