// ./src/components/Timer.tsx
import React, { useState, useEffect, useRef } from 'react';

export const getDisplayTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};


interface TimerProps {
    className?: string;
    delay?: number;
    duration: number;
}

const Timer = ({ className, delay = 0, duration }: TimerProps) => {
    const [timeLeft, setTimeLeft] = useState(delay + duration);

    useEffect(() => {
        if (timeLeft > 0) {
            const timerId = setTimeout(() => {
                setTimeLeft(timeLeft - 1);
            }, 1000);
            return () => clearTimeout(timerId);
        }
    }, [timeLeft]);


    /* const getColor = (timeLeft: number) => {
        if (timeLeft <= duration / 5) {
            return 'red';
        } else if (timeLeft <= duration / 3) {
            return 'yellow';
        } else {
            return 'green';
        }
    }; */

    return (
        <div className={className ? className : ''} /* style={{ color: getColor(timeLeft) }} */>
            {getDisplayTime(timeLeft)}
        </div>
    );
};

interface TimerPlusProps {
    start: boolean;
    paused?: boolean
    className?: string;
}
const TimerPlus: React.FC<TimerPlusProps> = ({ start, paused = false, className = null }) => {
    const [time, setTime] = useState(0);
    const timerRef = useRef<NodeJS.Timeout>();

    useEffect(() => {
        if (start && !paused) {
            timerRef.current = setInterval(() => {
                setTime(prevTime => prevTime + 1);
            }, 1000);
        } else if (!start) {
            clearInterval(timerRef.current);
        }
        return () => clearInterval(timerRef.current);
    }, [start, paused]);

    return (
        <div className={className ? className : ''}>
            {getDisplayTime(time)}
        </div>
    );
};

export { Timer, TimerPlus };