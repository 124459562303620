// ./src/components/InputWall.tsx
import ms from './style/InputWall.module.css';
import React, { useState, useRef, useEffect, useContext, ChangeEvent, KeyboardEvent } from 'react';
import { observer } from 'mobx-react-lite';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';

import { ThemeContext } from '../context/ThemeContext';

export default observer(() => {
    const [inputs, setInputs] = useState<string[]>(['']);
    const [activeInput, setActiveInput] = useState<number>(0);

    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
    const { darkTheme } = useContext(ThemeContext);

    const handleInputChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
        const newInputs = [...inputs];
        newInputs[index] = event.target.value;
        setInputs(newInputs);
    };

    const handleKeyDown = (index: number, event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' || (event.key === 'Tab' && !event.shiftKey)) {
            event.preventDefault();
            addInput(index + 1);
        } else if (event.key === 'Tab' && event.shiftKey) {
            event.preventDefault();
            setActiveInput(index - 1);
        } else if (event.key === 'Backspace' && inputs[index] === '') {
            event.preventDefault();
            removeInput(index);
        }
    };

    const handleBlur = (index: number) => {
        if (inputs[index] === '') {
            removeInput(index);
        }
    };

    const handleMouseDown = (index: number) => {
        setActiveInput(index);
    };

    const addInput = (index: number) => {
        if (inputs.length >= 10) {
            if (index < 10)
                setActiveInput(index)
            return;
        };
        if (index === 1 && inputs[0] === '') return;
        const newInputs = [...inputs];
        newInputs.splice(index, 0, '');
        setInputs(newInputs);
        setActiveInput(index);
    };

    const removeInput = (index: number) => {
        if (inputs.length <= 1) return;
        const newInputs = [...inputs];
        newInputs.splice(index, 1);
        setInputs(newInputs);
        setActiveInput(index - 1);
    };

    useEffect(() => {
        if (inputRefs.current[activeInput]) {
            inputRefs.current[activeInput]?.focus();
        }
    }, [activeInput, inputs]);

    useEffect(() => {
        if (inputs.length === 0) {
            setInputs(['']);
        }
    }, [inputs]);

    return (
        <div className={darkTheme ? ms.theme_dark : ms.theme_light}>
            {inputs.map((input, index) => (
                <div key={index} className={`${ms.input_container} ${activeInput === index ? ms.active : ''}`}>
                    {(
                        <FontAwesomeIcon icon={faBan} onClick={() => removeInput(index)} className={ms.icon} />
                    )}
                    <span>#{index + 1}{' '}</span>
                    <input
                        type="text"
                        value={input}
                        onChange={event => handleInputChange(index, event)}
                        onKeyDown={event => handleKeyDown(index, event)}
                        onBlur={() => handleBlur(index)}
                        onMouseDown={() => handleMouseDown(index)}
                        ref={ref => inputRefs.current[index] = ref}
                        className={`${ms.input} montserrat`}
                        placeholder='начните вводить...'
                    />
                </div>
            ))}
            {inputs.length < 10 && (
                <div onClick={() => addInput(inputs.length)} className={ms.add_input}>
                    #{inputs.length + 1} [Таб/Enter для продолжения]
                </div>
            )}
        </div>
    );
});