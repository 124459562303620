import React, { useContext } from "react";
import ms from './FindPair.module.css';

import { GameContext } from "./FindPair";

import Spinner from "../../../../components/Spinner";
import Tile from "./Tile";

const GameField: React.FC = () => {
    const { state } = useContext(GameContext);

    return (<div className={`${ms.gamefield} ${ms.dragging} ${(state.isPaused || state.levelCompleted) ? ms.modal__activate : ''} ${state.hardMode ? ms['gfl' + state.level] : ms.gfl1}`}>
        {
            (state.grid.length === 0) ? (
                <Spinner size="30" color="#fadf12" />
            ) : (
                state.grid.map((_: any, index: number) => {
                    return <Tile key={"TILE" + index} index={index} />

                })
            )
        }
    </div>)
}

export default GameField;

