import React, { FC } from "react";
import ms from '../components/style/InputElement.module.css';

interface InputElementProps {
    label: string;
    value: string;
    setValue: (value: string) => void;
    type: string;
    required: boolean;
}

const InputElement: FC<InputElementProps> = ({ label, value, setValue, type, required }) => {
    return (
        <div className={`${ms.wrap} ${value !== '' ? ms.wrap_changed : ''}`}>
            <input
                className={`${ms.field}`}
                type={type}
                title={label}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                required={required}
            />
            <label className={ms.label}>{label}</label>
        </div>
    );
};

export default InputElement;